const { createElement } = require('react');
const { renderToStaticMarkup } = require('react-dom/server');
const Icon = require('./serialized-icons');
const { linkResolver } = require('./link-resolver');

function processContentForIcons (children) {
  let childrenProcessed = children;

  if (/{{(.*?)}}/g.test(children)) {
    childrenProcessed = children.replaceAll(/{{(.*?)}}/g, (str, iconName) => {
      return renderToStaticMarkup(createElement(Icon, {
        name: iconName
      }));
    });
  }

  return childrenProcessed;
}

function fixLinkButtonWrapping (childrenUnprocessed) {
  const m = childrenUnprocessed.match(/^(<a [^>]+>)(<span class="Button[^>]+>)(.+)<\/span><\/a>$/);

  if (m) {
    return `${m[2]}${m[1]}${m[3]}</a></span>`;
  }

  return childrenUnprocessed;
}

module.exports = function HtmlSerializer (type, element, content, childrenUnprocessed, key) {
  let children = childrenUnprocessed;

  if (type === 'paragraph' && element?.spans?.length) {
    children = processContentForIcons(fixLinkButtonWrapping(childrenUnprocessed));

    const span = element.spans.find((s) => {
      return s.type === 'label' && s.start === 0 && s.end === element.text?.length;
    });

    if (span?.data?.label) {
      let className = span.data.label.replace(/[^a-z0-9-]+/ig, () => '-').toLowerCase();
      return renderToStaticMarkup(createElement('p', {
        className, key,
        dangerouslySetInnerHTML: { __html: children }
      }));
    }
  }

  if (type === 'label' && element?.data?.label === 'Target-Blank') {
    children = processContentForIcons(childrenUnprocessed);

    return renderToStaticMarkup(createElement('span', {
      dangerouslySetInnerHTML: { __html: children.replaceAll('<a ', '<a target="_blank" ') }
    }));
  }

  if (type === 'label' && element?.data?.label === 'Info-Tooltip') {
    children = processContentForIcons(childrenUnprocessed);

    let className = element.data.label.replace(/[^a-z0-9-]+/ig, () => '-').toLowerCase();

    const theSpan = renderToStaticMarkup(createElement('span', {
      className: `${className}-inner`,
      dangerouslySetInnerHTML: { __html: children }
    }));

    return renderToStaticMarkup(createElement('span', {
      className, key,
      dangerouslySetInnerHTML: { __html: theSpan }
    }));
  }

  if (type === 'paragraph' && /\bhref="https:\/\/#/.test(children)) {
    children = processContentForIcons(childrenUnprocessed);
    children = children.replaceAll(/\bhref="https:\/\/#/g, 'href="#');
    children = children.replaceAll(' target="undefined" ', ' ');

    return renderToStaticMarkup(createElement('p', {
      key,
      dangerouslySetInnerHTML: { __html: children }
    }));
  }

  if (/{{(.*?)}}/g.test(children)) {
    children = processContentForIcons(childrenUnprocessed);

    if (type === 'paragraph') {
      return renderToStaticMarkup(createElement('p', {
        key,
        dangerouslySetInnerHTML: { __html: children }
      }));
    }

    if (type === 'label') {
      return renderToStaticMarkup(createElement('span', {
        dangerouslySetInnerHTML: { __html: children }
      }));
    }

    if (type === 'hyperlink') {
      const { data } = element;
      let { url } = data;
      const { target } = data;

      if (!url && data.id && data.type) {
        url = linkResolver(data);
      }

      return renderToStaticMarkup(createElement('a', {
        key, href: url, target: target,
        dangerouslySetInnerHTML: { __html: children }
      }));
    }
  }

  return null;
};
