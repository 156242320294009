import React, { useCallback, useEffect, useState } from "react";

export const LightboxContext = React.createContext({
  activeSrc: null,
  addActiveSrc: () => {},
  closeLightbox: () => {}
});

export function LightboxProvider ({ children }) {
  const [ activeSrc, setActiveSrc ] = useState(null);

  const addActiveSrc = (src) => setActiveSrc(src);

  const closeLightbox = () => {
    setActiveSrc(null);
  };

  const contextValue = {
    activeSrc,
    addActiveSrc: useCallback((src) => addActiveSrc(src), []),
    closeLightbox: useCallback(() => closeLightbox(), [])
  };

  useEffect(() => {
    if (activeSrc && !document.body.classList.contains('lightbox-active')) {
      document.body.classList.add('lightbox-active');
    }

    if (!activeSrc && document.body.classList.contains('lightbox-active')) {
      document.body.classList.remove('lightbox-active');
    }

    return () => {
      document.body.classList.remove('lightbox-active');
    };
  }, [ activeSrc ]);

  return (
    <LightboxContext.Provider value={contextValue}>
      {children}
    </LightboxContext.Provider>
  );
}
