/*eslint-env node*/
exports.linkResolver = function linkResolver (doc) {
  if (doc.uid === 'home' && doc.type === 'page') {
    return '/';
  }

  if (doc.type === 'article' && (doc.uid ?? doc.slug)) {
    return `/insights/${doc.uid ?? doc.slug}`;
  }

  if (doc.type === 'case_study' && (doc.uid ?? doc.slug)) {
    return `/portfolio/${doc.uid ?? doc.slug}`;
  }

  if (doc.type === 'insights_page') {
    return '/insights';
  }

  if (doc.type === '404_page') {
    return `/404`;
  }

  if (doc.uid ?? doc.slug) {
    return `/${(doc.uid ?? doc.slug).replace('.', '/')}`;
  }

  throw new Error(`Invalid type for link: ${JSON.stringify(doc)}`);
};
