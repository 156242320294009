/* eslint-env browser */
import React, { useEffect } from 'react';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import markerSDK from '@marker.io/browser';
import { linkResolver, htmlSerializer } from './src/utils/prismic-config';
import { LightboxProvider } from './src/common/lightbox/lightbox-provider';

function MarkerIoLoader ({ destination }) {
  useEffect(() => {
    if (!destination) return;

    markerSDK.loadWidget({
      destination
    }).then((widget) => {
      window.markerWidget = widget;
    });

    return () => {
      if (!window.markerWidget) return;
      window.markerWidget.unload();
    };
  }, [ destination ]);

  return null;
}

const repositoryConfigs = [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver, htmlSerializer
  }
];

export const wrapRootElement = ({ element }) => {
  return (
    <PrismicPreviewProvider
      repositoryConfigs={repositoryConfigs}
    >
      <MarkerIoLoader destination={process.env.GATSBY_MARKER_IO} />
      <LightboxProvider>
        {element}
      </LightboxProvider>
    </PrismicPreviewProvider>
  );
};

export const shouldUpdateScroll = () => [ 0, 0 ];

// Something in Gatsby is breaking ScrollHelper from gatsby-react-router-scroll.
// So to keep it from working, we break scrollTo();
const __scrollTo = window.scrollTo;

window.scrollTo = (...args) => {
  if (args[0] === 0 && args[1] === 0) {
    return __scrollTo.apply(window, args);
  }

  if (args.length === 1 && 'object' == typeof args[0]) {
    if (args[0].left === 0 && args[0].top === 0) {
      return __scrollTo.apply(window, args);
    }
  }

  console.debug('scrollTo() is intentionally broken to be noop.  See gatsby-browser.js');
};
