module.exports = [{
      plugin: require('../../../var/task/node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"level5"},
    },{
      plugin: require('../../../var/task/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/favicon-32x32.png","icons":[{"src":"static/android-chrome-192x192.png","sizes":"192x192","type":"images/png"},{"src":"static/favicon.ico","sizes":"512x512","type":"images/ico"}],"theme_color":"#B04C28","background_color":"#fff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"88be59a01edd2da2311d24be7aba89a0"},
    },{
      plugin: require('../../../var/task/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PCPNPJNS","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../var/task/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
